import clsx from 'clsx';
import React, { useState, useEffect } from 'react';
import {useHistory} from 'react-router-dom';
import BillingPlans from './BillingPlans/BillingPlans';
import CodeVerification from './CodeVerification/CodeVerification';
import EmailVerification from './EmailVerification/EmailVerification';
import PersonalDetailsProgress from './PersonalDetailsProgress/PersonalDetailsProgress';
import PersonalInfo from './PersonalInfo/PersonalInfo';
import {TERMS_URL, PRIVACY_URL} from "../constants";
import {loadStripe} from '@stripe/stripe-js';
import {useStore} from 'react-hookstore'

import './SignUp.css';
import {fetchLanguages, fetchPlans, verifyCode, verifyEmail, submitForm} from "./api";

const SignUp = () => {
  const [env] = useStore('environment')
  let key;
  if (env === 'production') {
    key = 'pk_live_51Ix6ETKVr8G3xSHetJ6D47zuD891PXBGtsegrqLk1aF5sjlmvyriy7uv8h5PXYy37PlJ1M4yYATd8Fq3XzdVRHAI00JEt5J2Lq'
  } else {
    key = 'pk_test_51Ix6ETKVr8G3xSHejlT1LHw9NBwwRXFyOXwmEqsyQuFpQ9OnTRLCWLTWY2u7LTzDHR2djSgXmsdl5aPYyM5qPYI300fgUWkAgx'
  }
  const stripePromise = loadStripe(key);
  const history = useHistory()
  const [plans, setPlans] = useState([]);
  const [languages, setLanguages] = useState();
  const [step, setStep] = useState('pricing-plans');
  const [form, setForm] = useState({ email: '', code: '' });
  const [loading, setLoading] = useState(true);
  const [selectedPlan, setSelectedPlan] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    getPlans().then(plans => {
      setPlans(plans.data)
      setLoading(false)
    })
    getLanguages().then(data => setLanguages(data))
  }, [])

  const getPlans = async () => {
    return await fetchPlans()
  }

  const getLanguages = async () => {
    const languages = await fetchLanguages()
    return languages.data
  }

  const cancel = () => {
    setStep('pricing-plans')
    setForm({ email: '', code: '', customerId: '' })
    setSelectedPlan('')
    setErrorMessage('')
  }

  const onSelectPlan = (planType) => {
    setStep('email-verification')
    setSelectedPlan(planType)
  }

  const onEmailSubmit = async () => {
    setLoading(true);
    try {
      const response = await verifyEmail(form.email, selectedPlan)
      if (response.status === 200) {
        setStep('code-verification');
        setErrorMessage('');
      } else {
        let error = await response.json()
        setErrorMessage(error.message)
      }
    }
    catch (error) {
      setErrorMessage("Unable to verify email")
    }
    setLoading(false)
  }

  const onCodeVerification = async () => {
    if (form.code) {
      setLoading(true);
      try {
        const response = await verifyCode(form.email, form.code)
        if (response.status === 200) {
          setStep('personal-info');
          setErrorMessage('');
        } else {
          let error = await response.json()
          setErrorMessage(error.message)
        }
      } catch (error) {
        setErrorMessage("Unable to verify email")
      }
      setLoading(false);
    } else {
      setErrorMessage('Please enter the verification code sent to your email')
    }
  }

  const onPersonalInfoSubmit = async (formData, language, timezone) => {
    for (const [key, value] of Object.entries(formData)) {
      if (value === '' && key !== 'phone') {
        setErrorMessage('Please make sure all the required fields are filled in')
        return
      }
    }
    setLoading(true)
    formData.language = language
    formData.timezone = timezone
    formData.username = form.email
    try {
      const response = await submitForm(formData)
      if (response.data !== null && response.data.hasOwnProperty("sessionId")) {
        //window.location.replace(response.data.sessionUrl)
        const stripe = await stripePromise
        stripe.redirectToCheckout({
          sessionId: response.data.sessionId
        }).then(() => history.push('/success'))
      } else if (response.status === 'failure') {
        setErrorMessage(response.message)
        setLoading(false)
      } else {
        history.push('/success')
      }
    } catch (error) {
      setErrorMessage("Unable to register user")
      setLoading(false)
    }
  }

  return (
    <div className={clsx('sign-up', step === 'pricing-plans' ? 'full-wrap' : 'center-wrap')}>
      <p className="signup-title">
        {step === 'pricing-plans' && 'Pricing plans'}
        {(step === 'email-verification' || step === 'code-verification') && 'Email verification'}
        {step === 'personal-info' && 'Complete Personal Info'}
      </p>
      { errorMessage ?
         <div className="error-message">
           { errorMessage }
         </div>
        :
        <p className="signup-description">
          {step === 'pricing-plans' && 'Choose your best pricing plan now!'}
          {step === 'email-verification' && 'Please enter your email'}
          {step === 'code-verification' && 'Please enter the verification code sent to your email.'}
          {step === 'personal-info' && 'Please fill in all the required fields below'}
        </p>
      }
      {step === 'personal-info' &&
        <PersonalDetailsProgress
          freePlanSelected={selectedPlan.productName === 'Free'}
        />
      }
      {step === 'pricing-plans' &&
        <BillingPlans
          onClick={onSelectPlan}
          loading={loading}
          plans={plans}
        />
      }
      {(step === 'email-verification' || step === 'code-verification') && (
        <EmailVerification
          value={form?.email}
          onChange={e => setForm({ ...form, email: e.target.value })}
          onSubmit={onEmailSubmit}
          loading={loading}
          step={step}
          setErrorMessage={setErrorMessage}
        />
      )}
      {step === 'code-verification' &&
        <CodeVerification
          onSubmit={onCodeVerification}
          onChange={e => setForm({ ...form, code: e.target.value })}
          loading={loading}
        />
      }
      {step === 'personal-info' &&
        <>
          <PersonalInfo
            cancel={cancel}
            freePlanSelected={selectedPlan.productName === 'Free'}
            onSubmit={onPersonalInfoSubmit}
            languages={languages}
            loading={loading}
          />
          <div className="policies">
            By signing up you agree to our <a href={TERMS_URL} target='_blank' rel='noreferrer'>
            terms of use
            </a> and <a href={PRIVACY_URL} target='_blank' rel='noreferrer'>
              privacy policy
            </a>.
          </div>
        </>
      }
    </div>
  );
};

export default SignUp;
