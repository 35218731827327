import React, { useEffect } from 'react'
import classnames from 'classnames'
import { useInView } from 'react-intersection-observer'
import { useStore } from 'react-hookstore'
import { useHistory } from 'react-router-dom';
import validUrl from 'valid-url'
import Login from '@taghub/component-login'
import { HEADER_HEIGHT, HOME_PATH } from '../../constants'
import './index.css'

export default function Top() {
    const [ref, inView] = useInView({ rootMargin: `-${HEADER_HEIGHT}px` })
    const [ , setNameVisible ] = useStore('nameVisible')
    const [ logging, setLogging ] = useStore('logging')
    // const [ , setContactMessage ] = useStore('contactMessage')
    const history = useHistory();

    const signUp = () => history.push('/signup');
    useEffect(() => {
        setNameVisible(inView)
    })

    function onLoggedIn () {
      setLogging(true)
      let redirectUrl = '/index'
      const urlParts = window.location.href.split('?')
      if (urlParts.length > 1) {
        const nextParts = urlParts[1].split('next=')
        if (nextParts.length > 1) {
          const url = decodeURIComponent(nextParts[1])
          if ((validUrl.isUri(url) && url.includes('taghub.net')) ||
              !validUrl.isUri(url)) { // case for relative paths
            redirectUrl = url
          }
        }
      }
      window.location = redirectUrl
    }

    const classes = classnames({ Top: true, logging })
    const styleImage = {
        background: `url("${HOME_PATH}/images/map.png")`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
    }
    return (
        <section className={classes} id='top'>
            <div className='Top__Form'>
                <img ref={ref} src={`${HOME_PATH}/images/taghub.png`} alt="taghub" />
                <h3>Connects your Supply Chain</h3>
                <Login
                  language={'en'}
                  defaultIsForgotVisible={
                    window.location.hash === '#/forgot' || 
                    window.location.pathname === '/forgot'
                  }
                  onLoggedIn={onLoggedIn}
                  onSignUp={() => signUp()}
                />
            </div>
            <div 
                className='Top__Image' 
                title='Gain a strategic view of assets and locations in your supply chain network'
                style={styleImage}>
            </div>
        </section>
    )
}
