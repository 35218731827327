import React from 'react';
import clsx from 'clsx';
import EmailIcon from './EmailIcon';
import arrow from '../../assets/icons/arrow-right.svg';

import './EmailVerification.css';

const EmailVerification = ({ value, onChange, onSubmit, loading, step, setErrorMessage }) => {

  const checkEmail = () => {
    if (step === 'email-verification') {
      if (value.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)) {
        onSubmit();
      } else {
        setErrorMessage('Please enter a valid email')
      }
    }
  }

  return (
    <div className="signup-form-wrapper">
      <div className="signup-input-block">
        <EmailIcon fill="#000" stroke="#000" />
        <input
          className={clsx(step === 'code-verification' && 'email-verification-disabled-input')}
          type="email"
          value={value}
          onChange={onChange}
          disabled={step === 'code-verification'}
        />
        <button
          className={clsx('email-button', step === 'code-verification' && 'disabled-button', step === 'email-verification' && 'enabled-button')}
          onClick={checkEmail}
        >
          {loading && step === 'email-verification' ? 'Verifying...' : 'Next Step'}
          <img src={arrow} alt="arrow" />
        </button>
      </div>
    </div>
  );
};

export default EmailVerification;
