import React from 'react';
import clsx from 'clsx';
import IncludedOptions from './IncludedOptions/IncludedOptions';

import './BillingPlan.css';

const BillingPlan = ({ plan, onClick }) => {
  return (
    <div className="billing-plan-wrap">
      {plan.productName === 'Business' && (
        <div className="billing-recommended">
          <span>recommended</span>
        </div>
      )}
      <div className={clsx("billing-plan", plan.productName === 'Business' && 'billing-recommended-plan')}>
        <p className="billing-title">{plan.productName}</p>
        <span className="billing-price">{plan.price}<sup className="billing-currency">NOK</sup></span>
        per month
        <button
          className="billing-signup-button"
          onClick={() => onClick(plan)}
        >Select</button>
        <IncludedOptions plan={plan}/>
        <div className="read-more">
          <p className="billing-read-more">Read more</p>
          <div className="plan-description">
            {plan.description}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillingPlan;
