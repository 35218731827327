import './utils/polyfills'
import 'intersection-observer'
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { createStore } from 'react-hookstore'
import monitoring from '@taghub/taghub-monitoring'
import env from '@taghub/taghub-environment'
import reportWebVitals from './reportWebVitals'
import Home from './Home'
import NotFound from './NotFound'
import Header from './Header'
import SignUp from './SignUp/SignUp';
import SuccessPage from "./SignUp/SuccessPage/SuccessPage";
import One from './One'
import { constantsToStyle, HOME_PATH } from './constants'
import screwIE from './utils/screwIE'
import RouteAdapter from './utils/RouteAdapter'
import pkg from '../package.json'
import './index.css'

monitoring.init({
    key: 'd1bca48b4e3648d9ab7155b5d4463824',
    project: 19,
    version: pkg.version
})

screwIE()

createStore('nameVisible', true)
createStore('logging', false)
createStore('contactMessage', '')
createStore('environment', env())

const style = constantsToStyle()

function App() {  
  return (
    <div className='App' style={style}>
      <Router>
        <Header />
        <RouteAdapter>
          <Switch>
            <Route exact path='/' component={Home} />
            <Route exact path={HOME_PATH} component={Home} />
            {/*<Route path='/terms' component={Terms} />
            <Route path='/privacy' component={Privacy} />*/}
            <Route path='/signup' component={SignUp} />
            <Route path="/success" component={SuccessPage} />
            <Route path="/forgot" component={Home} />
            <Route path='/one' component={One} />
            <Route component={NotFound} />
          </Switch>
        </RouteAdapter>
      </Router>
    </div>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>, 
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
