import React       from 'react'
import Top         from './Top'
import About       from './About'
import SupplyChain from './SupplyChain'
import Sharing     from './Sharing'
import Technology  from './Technology'
import Products    from './Products'
import Benefits    from './Benefits'
import Quality     from './Quality'
import Contact     from './Contact'
import Footer      from './Footer'
import './index.css'

export default function Home() {
  return (
    <div className='Home'>
      <Top />
      <About />
      <SupplyChain />
      <Sharing />
      <Technology />
      <Products />
      <Benefits />
      <Quality />
      <Contact />
      <Footer />
    </div>
  )
}