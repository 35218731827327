import React    from 'react'
import { ReactComponent as Facebook } from '@taghub/taghub-styling/graphics/social/facebook.svg'
import { ReactComponent as Twitter } from '@taghub/taghub-styling/graphics/social/twitter.svg'
import { ReactComponent as GitHub } from '@taghub/taghub-styling/graphics/social/github.svg'
import './index.css'
import {TERMS_URL, PRIVACY_URL, STATUS_URL} from "../../constants";

// const TERMS_URL = '#terms'
// const PRIVACY_URL = '#privacy'

export default function Footer() {
  return (
    <footer className='Footer'>
      <div className='Footer__Left'>
        <a href='https://www.facebook.com/smartmnorway' target='_blank' rel="noopener noreferrer"><Facebook /></a>
        <a href='https://twitter.com/taghub' target='_blank' rel="noopener noreferrer"><Twitter /></a>
        <a href='https://github.com/taghubnet' target='_blank' rel="noopener noreferrer"><GitHub /></a>
      </div>
      <div className='Footer__Right'>
        <span>
          <a href={STATUS_URL} target='_blank' rel='noreferrer'>Status</a> |&nbsp;
          <a href={PRIVACY_URL} target='_blank' rel='noreferrer'>Privacy</a> |&nbsp;
          <a href={TERMS_URL} target='_blank' rel='noreferrer'>Terms</a>
        </span>
        <span>
          © { (new Date()).getFullYear() } TagHub
        </span>
      </div>
    </footer>
  )
}
