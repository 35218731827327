import React from 'react'
import { ReactComponent as Pig } from '@taghub/taghub-styling/graphics/ecommerce/pig-dollar.svg'
import { ReactComponent as DataCollect } from '@taghub/taghub-styling/graphics/devices/data-collect.svg'
import { ReactComponent as ChartUp } from '@taghub/taghub-styling/graphics/ecommerce/chart-up.svg'
import { ReactComponent as Rocket } from '@taghub/taghub-styling/graphics/transport/rocket.svg'
import { ReactComponent as Lock } from '@taghub/taghub-styling/graphics/ecommerce/lock.svg'
import { ReactComponent as Thumb } from '@taghub/taghub-styling/graphics/social/thumb.svg'
import './index.css'

const Item = ({ title, description, icon }) => 
    <div className='Benefits__Item'>
        <div className='Benefits__Item__Image'>
            { icon }
        </div>
        <div className='Benefits__Item__Title'>{ title }</div>
        <div className='Benefits__Item__Description'>{ description }</div>
    </div>    

export default function Benefits() {
    return (
        <section className='Benefits' id='benefits'>
            <h2>Benefits</h2>
            <div className='Benefits__Items'>
                <Item 
                    title='Cost effective'
                    description='Lower hardware expenses & improve cash flow in the value chain'
                    icon={<Pig height={50} width={50} />}
                    />
                <Item 
                    title='Data capture'
                    description='Hand-held device use and big data access at your fingertips'
                    icon={<DataCollect height={85} />}
                    />
                <Item 
                    title='Increased Efficiency'
                    description='Real-time access to global data & enhanced metrics'
                    icon={<ChartUp height={40} />}
                    />
            </div>
            <div className='Benefits__Items'>
                <Item 
                    title='Fully automated'
                    description='Efficiently energize optimal deliverable without competitive applications'
                    icon={<Rocket height={40} />}
                    />
                <Item 
                    title='Secure'
                    description='Visibility across the network through advanced reporting and analysis'
                    icon={<Lock height={40} />}
                    />
                <Item 
                    title='Error Reduction'
                    description='Diminish mistakes with an integrated data management model'
                    icon={<Thumb height={30} />}
                    />
            </div>
        </section>
    )
}