import React from 'react';
import clsx from 'clsx';

import './PersonalDetailsProgress.css';

const PersonalDetailsProgress = ({ freePlanSelected }) => {

  const steps = !freePlanSelected ? ['Personal Info', 'Payment Details'] : ['Email Confirmation', 'Personal Info']

  return (
    <div className={clsx("progress", freePlanSelected && 'progress-last')}>
      {steps.map((progressStep, i) => (
        <div key={i} className={clsx(
          'step',
          i === 1 && freePlanSelected && 'selected',
          i === 0 && !freePlanSelected && 'selected',
          i === 1 && 'last-step'
        )}>
          <span>{progressStep}</span>
          <div>{i+1}</div>
        </div>
      ))}
    </div>
  );
};

export default PersonalDetailsProgress;
