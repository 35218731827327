import React from 'react';
import unlock from '../../assets/icons/unlock.svg';
import arrow from '../../assets/icons/arrow-right.svg'

import './CodeVerification.css';

const CodeVerification = ({ onSubmit, loading, onChange }) => {
  return (
    <div className="signup-form-wrapper">
      <div className="signup-input-block">
        <img src={unlock} alt="unlock"/>
        <input type="text" onChange={onChange}/>
      </div>
      <button
        className="code-verification-button"
        onClick={onSubmit}
      >
        {loading ? 'Verifying...' : 'Next Step'}
        <img src={arrow} alt="arrow" />
      </button>
    </div>
  )
}

export default CodeVerification;
