

export async function fetchPlans() {
  const response = await fetch(`/subscriptionTypes`)
  return await response.json()
}

export async function fetchLanguages() {
  const response = await fetch(`/languages`)
  return await response.json()
}

export async function verifyEmail(email, plan) {
  return await fetch(`/registration/email`, {
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'POST',
    body: JSON.stringify({ username: email, priceId: plan.priceId })
  })
}

export async function verifyCode(email, code) {
  return await fetch(`/registration/email`, {
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'POST',
    body: JSON.stringify({ username: email, code: code })
  })
}

export async function submitForm(data) {
  const response = await fetch(`/registration/user`, {
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'POST',
    body: JSON.stringify(data)
  })
  return await response.json()
}

