import React, { useState } from 'react';
import arrow from '../../assets/icons/arrow-right.svg';
import InputElement from './InputElement/InputElement';

import './PersonalInfo.css';
import clsx from "clsx";

const formFields = {'firstname': '', 'lastname': '', 'password': '', 'phone': ''};
const formLabels = ['First name *', 'Last name *', 'Password *', 'Phone'];
let timezones = []
for (let i=-12; i<=12; i++) {
    timezones[i] = i>=0 ? {id: i, name:`UTC+${i}`} : {id: i, name: `UTC${i}`}
}

const PersonalInfo = ({ cancel, freePlanSelected, onSubmit, languages, loading }) => {
  const [form, setForm] = useState(formFields);
  const [timezone, setTimezone] = useState(timezones[2].id);
  const [language, setLanguage] = useState(languages[1].code);

  return (
    <div className="personal-info">
      <div className="personal-info-form">
        {Object.keys(formFields).map((input, index) => <InputElement
          key={input}
          label={formLabels[index]}
          value={form[input]}
          onChange={e => setForm({ ...form, [input]: e.target.value })}
        />)}
        <span>Language</span>
        <span>Timezone</span>
        <select defaultValue={language} onChange={(e) => setLanguage(e.target.value)}>
          { languages.map(((value, index) => {
            return (
              <option value={value.code} key={index}>{value.name}</option>
            )
          }))
          }
        </select>
        <select defaultValue={timezone} onChange={(e) => setTimezone(e.target.value)}>
          { timezones.map(((value, index) => {
            return (
              <option value={value.id} key={value.id}>{value.name}</option>
            )
          }))
          }
        </select>
      </div>
      <button className={clsx('signup-next-step-button', loading && 'disabled-button', !loading && 'enabled-button')} onClick={() => onSubmit(form, language, timezone)}>
        {loading && 'Processing...'}
        {!loading && freePlanSelected && 'Sign Up'}
        {!loading && !freePlanSelected && 'Next Step'}
        {!freePlanSelected && !loading && <img src={arrow} alt="arrow"/>}
      </button>
      <button className="cancel-button" onClick={cancel}>Cancel</button>
    </div>
  );
};

export default PersonalInfo;
