import React from 'react'
import './index.css'

export default function About() {
  return (
    <section className='About' id='about'>
      <h2>About Us</h2>
      <p>
        <em>Smart Management</em> provides specialist integrated solutions for the supply chain management (SCM), asset management, logistics & transportation sectors. Through extensive experience in the Norwegian oil and gas industry we are proud developers of the innovative TagHub software.
      </p>
      <p>
        <em>TagHub</em> is a highly-advanced SCM and asset management system that improves control and maximises efficiency for a seamless end-to-end supply chain operation. Our cloud based technology, offers extra flexibility and increased collaboration between teams that can access data remotely on any device, while also ensuring that costs on additional infrastructure are kept to a minimum. This makes TagHub ideal for companies of any size across a variety of industries.
      </p>
    </section>
  )
}