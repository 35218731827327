import React from 'react';
import './SuccessPage.css';
import {Link} from "react-router-dom";
import { HOME_PATH } from '../../constants'

const SuccessPage = () => {
  return (
    <div className="success">
      <p className="success-title">Welcome to TagHub!</p>
      <p>Your registration was successful. Please click <Link to={HOME_PATH} className='link-to-login'>here</Link> to login to your account!</p>
      <p>Have a good day!</p>
    </div>
  );
};

export default SuccessPage;
