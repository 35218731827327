
import './BillingPlans.css';
import BillingPlan from "./BillingPlan/BillingPlan";
import {useStore} from "react-hookstore";
import { useHistory } from 'react-router-dom'
import { ENTERPRISE_MESSAGE, HOME_PATH } from "../../constants"

const BillingPlans = ({ onClick, loading, plans }) => {
  const history = useHistory()

  const [ , setContactMessage ] = useStore('contactMessage')
  const onContactClick = () => {
    setContactMessage(ENTERPRISE_MESSAGE)
    history.push(HOME_PATH)
  }

  return (
    <div className="billing-plans">
      { loading ?
        <div className="loading"></div>
        :
        <div className="plans-wrapper">
          {plans.map(plan => <BillingPlan key={plan.productName} plan={plan} onClick={onClick} />)}
        </div>
      }
      <div className="plans-enterprise">
        <div>
          For <b>Enterprise</b>, please contact us <a onClick={onContactClick} className="contact-link" href="#/contact-form">here</a>.
        </div>
      </div>
    </div>
  )
}

export default BillingPlans;
