import React, {useState, useEffect, useRef} from 'react'
import {useStore} from 'react-hookstore'
//import getReCaptchaSiteKey from '../../../utils/getReCaptchaSiteKey'
import validateEmail from '../../../utils/validateEmail'
import './index.css'

const ContactForm = () => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useStore('contactMessage')
  const [nameClass, setNameClass] = useState('')
  const [emailClass, setEmailClass] = useState('')
  const [messageClass, setMessageClass] = useState('')
  const [response, setResponse] = useState('')
  //const recaptchaSiteKey = getReCaptchaSiteKey(env)
  //const [disabledForm, setDisabledForm] = useState(!!recaptchaSiteKey)

  const messageRef = useRef()
  useEffect(() => {
    if (message) {
      messageRef.current.focus()
    }
  }, [message])

  const onNameChange = e => {
    setNameClass('')
    setName(e.target.value)
  }
  const onEmailChange = e => {
    setEmailClass('')
    setEmail(e.target.value)
  }
  const onMessageChange = e => {
    setMessageClass('')
    setMessage(e.target.value)
  }
  const onKeyUp = e => {
    if (e.keyCode === 13) {
      onSubmit()
    }
  }
  const onSubmit = async e => {
    e && e.preventDefault()
    if (!name) {
      setNameClass('error')
    }
    if (!email || !validateEmail(email)) {
      setEmailClass('error')
    }
    if (!message) {
      setMessageClass('error')
    }

    if (!name || !email || !message || !validateEmail(email)) {
      return
    }

    let body = JSON.stringify({name, email, message})
    let response = await fetch('/contact/', {method: 'POST', body})
    if (response.status === 200) {
      setResponse(`Thanks for contacting us! We'll get back to you by e-mail :)`)
    } else {
      setResponse(`Could not send the message :/`)
    }
    setMessage('')
    setTimeout(() => setResponse(''), 5000)
  }

  return (
    <form className='ContactForm' id='contact-form'>
      <input
        className={nameClass}
        type="text"
        placeholder='Name'
        value={name}
        onKeyUp={onKeyUp}
        onChange={onNameChange}
      />
      <input
        className={emailClass}
        type="text"
        placeholder='Email'
        value={email}
        onKeyUp={onKeyUp}
        onChange={onEmailChange}
      />
      {
        response
          ? (
            <div className='ContactForm__Response'>
              {response}
            </div>
          )
          : (
            <React.Fragment>
              <textarea
                className={messageClass}
                rows='8'
                placeholder='Your Message'
                value={message}
                ref={messageRef}
                onChange={onMessageChange}
              />

                {/*{recaptchaSiteKey && (
                  <Recaptcha
                    sitekey={recaptchaSiteKey}
                    size="compact"
                    render="explicit"
                    verifyCallback={(response) => {
                        console.log(response)
                        setDisabledForm(false)
                    }}
                    onloadCallback={() => null}
                    expiredCallback={() => null}
                  />
                )}*/}

                <button onClick={onSubmit}>Send</button>
            </React.Fragment>
          )
      }
    </form>
  )
}

export default ContactForm