import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

// This adapter redirects some routes that previously were with '#', 
// to not break the links of other apps pointing here.
// 
export default function RouteAdapter({ children }) {
  const history = useHistory()

  useEffect(() => {
    const pages = ['signup', 'success', 'forgot']
    const pageName = window.location.hash.replace('#/', '').replace('#', '').trim()
    if (pages.find(page => page === pageName)) {
      window.location.hash = ''
      history.push(`/${pageName}`)
    }
  }, [history])

  return children
}