import React from 'react'
import { ReactComponent as Email } from '@taghub/taghub-styling/graphics/social/email.svg'
import { ReactComponent as Phone } from '@taghub/taghub-styling/graphics/devices/phone.svg'
import { ReactComponent as Marker } from '@taghub/taghub-styling/graphics/maps/marker-2.svg'
import ContactForm from './ContactForm'
import './index.css'

export default function Contact() {
    return (
        <section className='Contact' id='contact'>
            <h2>Contact</h2>
            <div className='Contact__Content'>
                <div className='Contact__Content__Left'>
                    <h3>Get in Touch</h3>
                    <p>If you would like to request a demo, set up a meeting or have questions about any of our products, please do not hesitate to get in touch.</p>
                    <hr />
                    <ul>
                        <li><a href='mailto:sales@smartm.no'><Email height={12} /> sales@smartm.no</a></li>
                        <li><a href='tel:+47 917 51 579'><Phone height={20} /> +47 917 51 579</a></li>
                        <li>
                            <a href='https://goo.gl/maps/eS5i4r3GHeo' target='_blank' rel="noopener noreferrer">
                                <Marker height={16} /> Børehaugen 3, 4006 Stavanger. Norway
                            </a>
                        </li>
                    </ul>
                </div>
                <div className='Contact__Content__Right'>
                    <ContactForm />
                </div>
            </div>
        </section>
    )
}