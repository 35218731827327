import React, { useState } from 'react';
import show from '../../../assets/icons/show-password.svg';
import hide from '../../../assets/icons/hide-password.svg';

import './InputElement.css';

const InputElement = ({ label, value, onChange, withIcon = false, icon }) => {
  const [isVisible, setIsVisible] = useState(false);

  const isPassword = label.toLowerCase().indexOf('password') !== -1;

  return (
    <div className="input">
      <span>{label}</span>
      <div className="input-field">
        <input type={!isPassword ? 'text' : !isVisible ? 'password' : 'text'} value={value} onChange={onChange} />
        {isPassword && <img onClick={() => setIsVisible(!isVisible)} src={isVisible ? hide : show} alt="eye" />}
        {withIcon && <img src={icon} alt="icon" />}
      </div>
    </div>
  );
};

export default InputElement;
