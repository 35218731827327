import React from 'react';

import './IncludedOptions.css';

const IncludedOptions = ({plan}) => {
  return (
    <div className="included-wrap">
      <div className="included-header">
        <span className="desc">what's included</span>
      </div>
      <ul>
        {plan.included.map((item, index) => {
          return (
            <li key={index}>{item}</li>
          )
        })}
      </ul>
    </div>
  );
};

export default IncludedOptions;
