import { HOME_PATH } from '../../constants'
import './index.css'

const Product = ({ title, description }) => 
    <div className='Product'>
        <div className='Product__Title'>{ title }</div>
        <div className='Product__Description'>{ description }</div>
    </div>

export default function Products() {
    return (
        <section className='Products' id='products'>
            <h2>Products</h2>
            <div className='Products__Content'>
                <img 
                    src={`${HOME_PATH}/images/products.png`}
                    alt='Real-time strategic and tactical planning analysis'
                    />
                <div className='Products__List'>
                    <Product 
                        title='Production' 
                        description='Maximised production flexibility, improved process execution, and effective management of works orders and bill of materials' 
                        />
                    <Product 
                        title='Inspection' 
                        description='Facilitate quality inspection throughout the supply chain with hand-held devices that provide real-time actionable data' 
                        />
                    <Product 
                        title='Warehouse Management' 
                        description='Warehouse intelligence that supports multifaceted operations and delivers comprehensive inventory visibility' 
                        />
                    <Product 
                        title='Rental & Shipment Logistics' 
                        description='Logistical insight through detailed traceability, live updates, accurate reporting and seamless supply chain integration' 
                        />
                    <Product 
                        title='Tracking' 
                        description='Real-time asset and inventory tracking solutions through advanced RFID tags, smart sensor devices and Bluetooth tags' 
                        />
                    <Product 
                        title='Location' 
                        description='Full transparency, improved accuracy and enhanced operational efficiencies via automated geo-fencing and QR code technology' 
                        />
                </div>
            </div>
        </section>
    )
}