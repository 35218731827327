import React from 'react'
import QRCode from 'qrcode.react'
import { ReactComponent as Tag } from '@taghub/taghub-styling/graphics/devices/tag.svg'
import { ReactComponent as Sensor } from '@taghub/taghub-styling/graphics/devices/sensor.svg'
import { ReactComponent as Phone } from '@taghub/taghub-styling/graphics/devices/phone.svg'
import './index.css'

const TechItem = ({ title, description, alt, icon, color }) => 
    <div className='Technology__Item'>
        <div className='Technology__Item__Title'>{ title }</div>
        <div 
            className='Technology__Item__Image' 
            title={title}
            style={{ backgroundColor: color }}>
            { icon }
        </div>
        <div className='Technology__Item__Description'>{ description }</div>
    </div>

export default function Technology() {
    return (
        <section className='Technology' id="technology">
            <h2>Technology</h2>
            <div className='Technology__Items'>
                <TechItem 
                    title='QR Codes'
                    description='Real-time identification, monitoring and tracing of inventory'
                    icon={
                        <QRCode 
                            value='http://taghub.io/000000' 
                            size={48} 
                            bgColor='transparent' 
                            fgColor='#fff' 
                            />
                    }
                    color='#59d'
                    />
                <TechItem 
                    title='Tags'
                    description='Production line logistics, asset tracking and data generation'
                    icon={<Tag height={50} />}
                    color='#59d'
                    />
                <TechItem 
                    title='Sensors'
                    description='Transforms traditional feedback signals into true digital insights'
                    icon={<Sensor height={50} />}
                    color='#59d'
                    />
                <TechItem 
                    title='Apps'
                    description='Up-to-date intelligence of your entire supply chain'
                    icon={<Phone height={50} />}
                    color='#59d'
                    />
            </div>
        </section>
    )
}
