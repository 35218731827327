import { HOME_PATH } from '../../constants'
import './index.css'

export default function Quality() {
  return (
    <section className='Quality' id='quality'>
      <h2>Quality</h2>
      <h3>Certifications</h3>
      <div className='Quality__Certifications'>
        <p>Smart Management AS holds certificates for both <a href="https://www.iso.org/iso-9001-quality-management.html" target="_blank" rel="noreferrer">ISO 9001</a> and <a href="https://www.iso.org/isoiec-27001-information-security.html" target="_blank" rel="noreferrer">ISO 27001</a> standards. Find some of our certifications below.</p>
        <div className='Quality__CertificationList'>
          <Certification 
            link={`${HOME_PATH}/pdf/certificate.pdf`}
            img={`${HOME_PATH}/images/kiwa.png`}
            description='ISO Certificate' 
          />
          <Certification 
            link={`${HOME_PATH}/pdf/magnet.pdf`}
            img={`${HOME_PATH}/images/magnet.png`}
            description='Magnet JQS supplier' 
          />
        </div>
      </div>
      <h3>Security</h3>
      <div className='Quality__Security'>
        <p>Smart Management AS performs continous security monitoring and testing of our products. We also perform annual security reviews with 3rd party security analysts.</p>
        <div className='Quality__CertificationList'>
          <Security 
            link={`${HOME_PATH}/pdf/ncc-2021.pdf`}
            img={`${HOME_PATH}/images/ncc.png`}
            description='NCC 2021 security audit' 
          />
        </div>
      </div>
    </section>
  )
}

function Certification({ link, img, description }) {
  return (
    <div className='Certification'>
      <a href={link} target='_blank' rel='noreferrer'>
        <img src={img} alt='cert' />
      </a>
      <div className='description'>
        {description}
      </div>
    </div>
  )
}

function Security({ link, img, description }) {
  return (
    <div className='Security'>
      <a href={link} target='_blank' rel='noreferrer'>
        <img src={img} alt='sec' />
      </a>
      <div className='description'>
        {description}
      </div>
    </div>
  )
}
