import { HOME_PATH } from '../constants'
import detectOS from './os'
import './index.css'

export default function One() {
  const os = detectOS()
  return (
    <div className='One'>
      <h1>Download TagHub here:</h1>
      <div className='One__Download'>
        { (os === 'iOS' || os === null) &&
          <a href='https://apps.apple.com/no/app/taghub/id1546620330?l=nb'>
            <img src={`${HOME_PATH}/images/appstore.png`} alt='appstore' />
          </a>
        }
        { (os === 'Android' || os === null) &&
          <a href='https://play.google.com/store/apps/details?id=net.taghub.lite'>
            <img src={`${HOME_PATH}/images/playstore.png`} alt='playstore' />
          </a>
        }
      </div>
    </div>
  )
}