import React, { useState } from 'react'
import { useStore } from 'react-hookstore'
import useOnClickOutside from 'use-onclickoutside'
import { useSpring, animated } from 'react-spring'
import useWindowSize from '../utils/useWindowSize'
import { ReactComponent as Hamburger } from '@taghub/taghub-styling/graphics/menu.svg'
import { DEMO_MESSAGE, HOME_PATH } from '../constants'
import './index.css'
import {useHistory} from 'react-router-dom';

const MenuItems = () => {
    const [ , setContactMessage ] = useStore('contactMessage')
    const onDemoClick = () => {
        setContactMessage(DEMO_MESSAGE)
        history.push(HOME_PATH)
    }
    const history = useHistory()
    return (
        <React.Fragment>
            <a href='#about' onClick={() => history.push(HOME_PATH)}>About Us</a>
            <a href='#technology' onClick={() => history.push(HOME_PATH)}>Technology</a>
            <a href='#products' onClick={() => history.push(HOME_PATH)}>Products</a>
            <a href='#benefits' onClick={() => history.push(HOME_PATH)}>Benefits</a>
            <a href='#quality' onClick={() => history.push(HOME_PATH)}>Quality</a>
            <a href='#contact' onClick={() => history.push(HOME_PATH)}>Contact Us</a>
            <a href='#contact-form' className='MenuItem__Demo' onClick={onDemoClick}>
                Demo
            </a>
        </React.Fragment>
    )
}

const Menu = () => {
    const [menuVisible, setMenuVisible] = useState(false)
    const style = useSpring({ height: menuVisible ? 320 : 240 })
    const onMenuClick = () => setMenuVisible(!menuVisible)
    const onMenuClose = () => setMenuVisible(false)
    const ref = React.useRef(null)
    useOnClickOutside(ref, onMenuClose)
    return (
        <div className='Menu' ref={ref}>
            <Hamburger onClick={onMenuClick} />
            {
                menuVisible && 
                <animated.div className='MenuItems' style={style}>
                    <MenuItems />
                </animated.div>
            }
        </div>
    )
}

const Logo   = () => <img src={`${HOME_PATH}/images/logo.png`} alt="logo" />
const Taghub = () => <img src={`${HOME_PATH}/images/taghub.png`} alt="taghub" />

export default function Header() {
    const size = useWindowSize()
    const [ nameVisible ] = useStore('nameVisible')
    const showName = !nameVisible
    const widthToShowMenu = showName ? 800 : 655
    const history = useHistory()
    return (
        <header>
            <a className='HeaderLeft' href='#top' onClick={() => history.push(HOME_PATH)}>
                <Logo />
                {
                    showName && 
                    <Taghub />
                }
            </a>
            <div className='HeaderRight'>
            {
                size.width > widthToShowMenu
                ? <MenuItems />
                : <Menu />
            }
            </div>
        </header>
    )
}
